var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewDashBoard project-route-index"},[_c('div',{staticClass:"form-area"},[_c('div',{staticClass:"col-sm-12"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.data_loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('form',{staticClass:"company-settings-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Company Name: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.company_name),expression:"formData.company_name"}],staticClass:"form-control",class:{
                          'input-border-errors':
                            _vm.$v.formData.company_name.$dirty &&
                            !_vm.$v.formData.company_name.required
                        },attrs:{"type":"text","placeholder":"Company Name"},domProps:{"value":(_vm.formData.company_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "company_name", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Company Email: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.company_email),expression:"formData.company_email"}],staticClass:"form-control",class:{
                          'input-border-errors':
                            _vm.$v.formData.company_email.$dirty &&
                            (!_vm.$v.formData.company_email.required ||
                              !_vm.$v.formData.company_email.email)
                        },attrs:{"type":"email","placeholder":"Company Email"},domProps:{"value":(_vm.formData.company_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "company_email", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Company Phone: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.company_phone),expression:"formData.company_phone"}],staticClass:"form-control",class:{
                          'input-border-errors':
                            _vm.$v.formData.company_phone.$dirty &&
                            !_vm.$v.formData.company_phone.required
                        },attrs:{"type":"tel","placeholder":"Company Phone"},domProps:{"value":(_vm.formData.company_phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "company_phone", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Company Website: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.company_website),expression:"formData.company_website"}],staticClass:"form-control",class:{
                          'input-border-errors':
                            _vm.$v.formData.company_website.$dirty &&
                            (!_vm.$v.formData.company_website.required ||
                              !_vm.$v.formData.company_website.url)
                        },attrs:{"type":"text","placeholder":"Company Website"},domProps:{"value":(_vm.formData.company_website)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "company_website", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Company Address: "),_c('span',[_vm._v("*")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.company_address),expression:"formData.company_address"}],staticClass:"form-control",class:{
                          'input-border-errors':
                            _vm.$v.formData.company_address.$dirty &&
                            !_vm.$v.formData.company_address.required
                        },attrs:{"placeholder":"Project Note"},domProps:{"value":(_vm.formData.company_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "company_address", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v("Default Currency"),_c('span',[_vm._v("*")])]),_c('Select2',{class:{
                          'input-border-errors':
                            _vm.$v.formData.default_currency.$dirty &&
                            !_vm.$v.formData.default_currency.required
                        },attrs:{"options":_vm.Currency_options,"settings":{ placeholder: 'Default Currency' }},model:{value:(_vm.formData.default_currency),callback:function ($$v) {_vm.$set(_vm.formData, "default_currency", $$v)},expression:"formData.default_currency"}})],1)])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Google Map key: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.google_map_key),expression:"formData.google_map_key"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Google Map key"},domProps:{"value":(_vm.formData.google_map_key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "google_map_key", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v("Default Time Zone")]),_c('Select2',{class:{
                          'input-border-errors':
                            _vm.$v.formData.default_time_zone.$dirty &&
                            !_vm.$v.formData.default_time_zone.required
                        },attrs:{"options":_vm.time_zone_list,"settings":{
                          placeholder: 'Default Time Zone',
                          allowClear: true
                        }},model:{value:(_vm.formData.default_time_zone),callback:function ($$v) {_vm.$set(_vm.formData, "default_time_zone", $$v)},expression:"formData.default_time_zone"}})],1)])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v("Date Format")]),_c('Select2',{class:{
                          'input-border-errors':
                            _vm.$v.formData.date_format.$dirty &&
                            !_vm.$v.formData.date_format.required
                        },attrs:{"options":_vm.date_format_list,"settings":{ placeholder: 'D-M-Y', allowClear: true }},model:{value:(_vm.formData.date_format),callback:function ($$v) {_vm.$set(_vm.formData, "date_format", $$v)},expression:"formData.date_format"}})],1)])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v("Time Format*")]),_c('Select2',{class:{
                          'input-border-errors':
                            _vm.$v.formData.time_format.$dirty &&
                            !_vm.$v.formData.time_format.required
                        },attrs:{"options":_vm.time_format_list,"settings":{
                          placeholder: '12 Hour (6:20 PM)',
                          allowClear: true
                        }},model:{value:(_vm.formData.time_format),callback:function ($$v) {_vm.$set(_vm.formData, "time_format", $$v)},expression:"formData.time_format"}})],1)])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-primary default-global-btn",attrs:{"disabled":_vm.disableSubmitBtn,"type":"submit","id":"save-form"}},[_vm._v(" Create ")]),(_vm.disableSubmitBtn)?_c('div',{staticClass:"form_submit_loader"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])]):_vm._e()])])])])])])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }