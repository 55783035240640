<template>
  <div class="viewDashBoard project-route-index" v-cloak>
    <div class="form-area">
      <div class="col-sm-12">
        <transition
          name="fade"
          mode="out-in"
          @beforeLeave="beforeLeave"
          @enter="enter"
          @afterEnter="afterEnter"
        >
          <div key="1" v-if="data_loader" class="white-row loader-route">
            <div class="lds-sub-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-sm-12">
              <form
                class="company-settings-form"
                v-on:submit.prevent="onSubmit($event)"
              >
                <div class="row">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="form-group">
                        <label for="email">Company Name: <span>*</span></label>
                        <input
                          v-model="formData.company_name"
                          type="text"
                          class="form-control"
                          placeholder="Company Name"
                          :class="{
                            'input-border-errors':
                              $v.formData.company_name.$dirty &&
                              !$v.formData.company_name.required
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="form-group">
                        <label for="email">Company Email: <span>*</span></label>
                        <input
                          v-model="formData.company_email"
                          type="email"
                          class="form-control"
                          placeholder="Company Email"
                          :class="{
                            'input-border-errors':
                              $v.formData.company_email.$dirty &&
                              (!$v.formData.company_email.required ||
                                !$v.formData.company_email.email)
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="form-group">
                        <label for="email">Company Phone: <span>*</span></label>
                        <input
                          v-model="formData.company_phone"
                          type="tel"
                          class="form-control"
                          placeholder="Company Phone"
                          :class="{
                            'input-border-errors':
                              $v.formData.company_phone.$dirty &&
                              !$v.formData.company_phone.required
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="form-group">
                        <label for="email"
                          >Company Website: <span>*</span></label
                        >
                        <input
                          v-model="formData.company_website"
                          type="text"
                          class="form-control"
                          placeholder="Company Website"
                          :class="{
                            'input-border-errors':
                              $v.formData.company_website.$dirty &&
                              (!$v.formData.company_website.required ||
                                !$v.formData.company_website.url)
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="form-group">
                        <label for="email"
                          >Company Address: <span>*</span></label
                        >
                        <textarea
                          v-model="formData.company_address"
                          class="form-control"
                          placeholder="Project Note"
                          :class="{
                            'input-border-errors':
                              $v.formData.company_address.$dirty &&
                              !$v.formData.company_address.required
                          }"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="form-group">
                        <label for="gender" class="control-label"
                          >Default Currency<span>*</span></label
                        >
                        <Select2
                          v-model="formData.default_currency"
                          :options="Currency_options"
                          :settings="{ placeholder: 'Default Currency' }"
                          :class="{
                            'input-border-errors':
                              $v.formData.default_currency.$dirty &&
                              !$v.formData.default_currency.required
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="form-group">
                        <label for="email"
                          >Google Map key: <span>*</span></label
                        >
                        <input
                          v-model="formData.google_map_key"
                          type="text"
                          class="form-control"
                          placeholder="Google Map key"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="form-group">
                        <label for="gender" class="control-label"
                          >Default Time Zone</label
                        >
                        <Select2
                          v-model="formData.default_time_zone"
                          :options="time_zone_list"
                          :settings="{
                            placeholder: 'Default Time Zone',
                            allowClear: true
                          }"
                          :class="{
                            'input-border-errors':
                              $v.formData.default_time_zone.$dirty &&
                              !$v.formData.default_time_zone.required
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="form-group">
                        <label for="gender" class="control-label"
                          >Date Format</label
                        >
                        <Select2
                          v-model="formData.date_format"
                          :options="date_format_list"
                          :settings="{ placeholder: 'D-M-Y', allowClear: true }"
                          :class="{
                            'input-border-errors':
                              $v.formData.date_format.$dirty &&
                              !$v.formData.date_format.required
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="form-group">
                        <label for="gender" class="control-label"
                          >Time Format*</label
                        >
                        <Select2
                          v-model="formData.time_format"
                          :options="time_format_list"
                          :settings="{
                            placeholder: '12 Hour (6:20 PM)',
                            allowClear: true
                          }"
                          :class="{
                            'input-border-errors':
                              $v.formData.time_format.$dirty &&
                              !$v.formData.time_format.required
                          }"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12">
                    <div class="row">
                      <div class="form-actions">
                        <button
                          :disabled="disableSubmitBtn"
                          type="submit"
                          id="save-form"
                          class="btn btn-primary default-global-btn"
                        >
                          Create
                        </button>
                        <div v-if="disableSubmitBtn" class="form_submit_loader">
                          <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "v-select2-component";
import { validationMixin } from "vuelidate";
const {
  required,
  minLength,
  email,
  numeric,
  url
} = require("vuelidate/lib/validators");
export default {
  mixins: [validationMixin],
  components: {
    Select2
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  validations: {
    formData: {
      company_name: {
        required
      },
      company_email: {
        required,
        email
      },
      company_phone: {
        required
      },
      company_website: {
        required,
        url
      },
      company_address: {
        required
      },
      default_currency: {
        required
      },
      default_time_zone: {
        required
      },
      date_format: {
        required
      },
      time_format: {
        required
      }
    }
  },
  data() {
    return {
      time_format_list: [
        {
          id: "h:i A",
          text: "12 Hour  (6:20 PM)"
        },
        {
          id: "H:i a",
          text: "12 Hour  (6:20 pm)"
        },
        {
          id: "H:i",
          text: "24 Hour  (18:20)"
        }
      ],
      date_format_list: [
        {
          id: "d-m-Y",
          text: "d-m-Y"
        },
        {
          id: "m-d-Y",
          text: "m-d-Y"
        },
        {
          id: "Y-m-d",
          text: "Y-m-d"
        },
        {
          id: "d.m.Y",
          text: "d.m.Y"
        },
        {
          id: "m.d.Y",
          text: "m.d.Y"
        },
        {
          id: "Y.m.d",
          text: "Y.m.d"
        },
        {
          id: "d/m/Y",
          text: "d/m/Y"
        },
        {
          id: "m/d/Y",
          text: "m/d/Y"
        },
        {
          id: "Y/m/d",
          text: "Y/m/d"
        },
        {
          id: "d-M-Y",
          text: "d-M-Y"
        },
        {
          id: "d/M/Y",
          text: "d/M/Y"
        },
        {
          id: "d.M.Y",
          text: "d.M.Y"
        },
        {
          id: "d-M-Y",
          text: "d-M-Y"
        },
        {
          id: "d M Y",
          text: "d M Y"
        },
        {
          id: "d F, Y",
          text: "d F, Y"
        },
        {
          id: "D/M/Y",
          text: "D/M/Y"
        },
        {
          id: "D.M.Y",
          text: "D.M.Y"
        },
        {
          id: "D-M-Y",
          text: "D-M-Y"
        },
        {
          id: "D M Y",
          text: "D M Y"
        },
        {
          id: "d D M Y",
          text: "d D M Y"
        },
        {
          id: "D d M Y",
          text: "D d M Y"
        },
        {
          id: "dS M Y",
          text: "dS M Y"
        }
      ],
      Currency_options: null,
      time_zone_list: null,
      formData: {
        settings_id: null,
        company_name: null,
        company_email: null,
        company_phone: null,
        company_website: null,
        company_address: null,
        default_currency: null,
        google_map_key: null,
        default_time_zone: null,
        date_format: null,
        time_format: null
      },
      disableSubmitBtn: false,
      data_loader: true
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.getCurrency();
    this.getICompanyData();
  },
  methods: {
    async onSubmit(event) {
      const $this = this;
      $this.disableSubmitBtn = true;
      $this.$v.$touch();

      if ($this.$v.$invalid) {
        $this.$store.dispatch("showtostrMessageNotifications", {
          message: "Please fill all the fields to proceed form!",
          type: "error"
        });
        $this.disableSubmitBtn = false;
        return;
      }
      const data = $this.$store.dispatch("postHttp", {
        url: "settings/company-settings/update",
        formDetails: this.formData,
        formEvent: event.target
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.$store.dispatch("displayMessageErrors", {
            response: response,
            formEvent: event.target
          });
          // $this.$router.push({path: '/project-status'});
          $this.disableSubmitBtn = false;
        }
      });
      $this.disableSubmitBtn = false;
    },
    async getCurrency() {
      const $this = this;
      $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url: "filters/get-curriens"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.Currency_options = response.data.data;
        }
      });
    },
    async getICompanyData() {
      const $this = this;
      $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url: "settings/company-settings"
      });
      await data.then(function(response) {
        if (typeof response != "undefined") {
          $this.formData.settings_id = response.data.data.id;
          $this.formData.company_name = response.data.data.company_name;
          $this.formData.company_email = response.data.data.company_email;
          $this.formData.company_phone = response.data.data.company_phone;
          $this.formData.company_website = response.data.data.website;
          $this.formData.company_address = response.data.data.address;
          $this.formData.default_currency = response.data.data.currency_id;
          $this.formData.google_map_key = response.data.data.google_map_key;
          $this.formData.default_time_zone = response.data.data.timezone;
          $this.formData.date_format = response.data.data.date_format;
          $this.formData.time_format = response.data.data.time_format;
          $this.data_loader = false;
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>

<style lang="scss" scoped>
body .form-area .form-group label span {
  color: red;
}
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.project-route-index {
  .show-project-portal-listing {
    .general-filters {
      .form-group {
        width: 100%;
      }
      .form-actions {
        width: 100%;
        button {
          width: 100%;
          margin-left: 0;
          padding: 5px 0;
        }
      }
      span.select2.select2-container.select2-container--default {
        width: 100% !important;
      }
    }
    h4 {
      letter-spacing: 0.28px;
      color: #323f54;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 7px;
    }
    p {
      margin: 0;
      letter-spacing: 0.28px;
      color: #2a1c0b;
    }
    section {
      border-bottom: 1px solid #ebebeb;
      padding: 30px 0;
      &.client-projects {
        padding-bottom: 0px;
        h4 {
          padding: 5px 0 25px;
        }
        .wrap-box {
          background: #faf9f7 0% 0% no-repeat padding-box;
          border-radius: 3px;
          padding: 20px 15px;
          min-height: 231px;
          margin-bottom: 30px;
          .box {
            position: relative;
            .project-details {
              p {
                &.project-id {
                  background: #ece9df 0% 0% no-repeat padding-box;
                  border-radius: 3px;
                  display: inline-block;
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 4px 10px;
                }
                &.project-status {
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 3px 0 15px;
                  font-weight: 400;
                }
              }
              .project-listing {
                padding: 15px 0 15px;
                p {
                  margin: 0;
                  letter-spacing: 0.28px;
                  color: #323f54;
                  font-size: 14px;
                  font-weight: bold;
                  text-transform: inherit;
                  line-height: 25px;
                }
              }
            }
            .footer-box {
              display: block;
              position: relative;
              width: 100%;
              padding: 20px 0 10px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              .usr-role {
                p {
                  letter-spacing: 0.24px;
                  color: #918a82;
                  font-weight: 300;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
